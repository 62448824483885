import React from 'react';
import styled from 'styled-components';
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const TitleWithIcon = ({ title, prefixIcon, suffixIcon, size }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  if (prefixIcon && title) {
    return (
      <Div size={size} color={solaTheme.componentColors.TitleTxColor}>
        <IconSvg size={size}>{React.createElement(prefixIcon)}</IconSvg>
        <div>{title}</div>
        {suffixIcon ? <IconSvg size={size}>{React.createElement(suffixIcon)}</IconSvg> : null}
      </Div>
    );
  }
  return null;
};

const Div = styled.div`
  min-height: 50px;
  margin: 0px 15px;

  display: flex;
  align-items: center;
  font-size: ${(props) => props.size || 18}px;
  font-weight: bold;
  color: ${(p) => p.color};

  .svgBase {
    fill: ${(p) => p.color || ''};
  }
`;

const IconSvg = styled.div`
  margin-right: 10px;
  padding-top: 6px;
  width: ${(props) => props.size || 18}px;
`;

export default TitleWithIcon;
