import React, { useMemo } from 'react';
import styled from 'styled-components';

import IconTooltip from '../Tooltip/IconTooltip';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const Weather = ({ wxinfo }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const wItem = useMemo(() => {
    const rtnVal = {
      tipItem: {
        text: '',
        dom: null,
        styles: {
          width: '120px',
          top: '-155px',
          left: '-95px',
          bgColor: solaTheme.componentColors.TipBgColor,
        },
      },
      iconItem: {
        icon: null,
        iconImg: null,
        alt: 'weather-icon',
        width: '48px',
        height: '48px',
        useCustomColor: false,
        baseColor: null,
        hoverColor: null,
      },
    };
    const tableStyle = {
      thTxColor: solaTheme.componentColors.TipTitleColor,
      tdTxColor: solaTheme.componentColors.TipTxColor,
    };

    if (wxinfo) {
      //setting icon
      switch (wxinfo.code) {
        case 1:
          rtnVal.iconItem.iconImg = solaTheme.componentColors.ProfitCardWeatherDayIcon;
          break;
        case 2:
          rtnVal.iconItem.iconImg = solaTheme.componentColors.ProfitCardWeatherDayCloudyIcon;
          break;
        case 3:
        case 7:
          rtnVal.iconItem.iconImg = solaTheme.componentColors.ProfitCardWeatherCloudyIcon;
          break;
        case 4:
          rtnVal.iconItem.iconImg = solaTheme.componentColors.ProfitCardWeatherOvercastIcon;
          break;
        case 5:
        case 9:
          rtnVal.iconItem.iconImg = solaTheme.componentColors.ProfitCardWeatherCloudyRainyIcon;
          break;
        case 6:
          rtnVal.iconItem.iconImg = solaTheme.componentColors.ProfitCardWeatherRainyIcon;
          break;
        case 8:
          rtnVal.iconItem.iconImg = solaTheme.componentColors.ProfitCardWeatherThunderRainyIcon;
          break;
        default:
          rtnVal.iconItem.iconImg = solaTheme.componentColors.ProfitCardWeatherOvercastIcon;
          break;
      }

      //setting dom
      rtnVal.tipItem.dom = (
        <TipDiv>
          <TipTable styles={tableStyle}>
            <tbody>
              <tr>
                <TipTh className="tipTh">降雨率</TipTh>
                <TipTd className="tipTd">{wxinfo.rain === null ? '--' : wxinfo.rain + ' %'}</TipTd>
              </tr>
              <tr>
                <TipTh className="tipTh">氣溫</TipTh>
                <TipTd className="tipTd">{wxinfo.temp === -1 ? '--' : wxinfo.temp + ' ℃'}</TipTd>
              </tr>
              <tr>
                <TipTh className="tipTh">相對溼度</TipTh>
                <TipTd className="tipTd">{wxinfo.rh === -1 ? '--' : wxinfo.rh + ' %'}</TipTd>
              </tr>
            </tbody>
          </TipTable>
        </TipDiv>
      );
    } else {
      //rtnVal.iconItem.iconImg = solaTheme.componentColors.ProfitCardWeatherOvercastIcon;
      // rtnVal.tipItem.dom = (
      //   <TipDiv>
      //     <TipTable styles={tableStyle}>
      //       <tbody>
      //         <tr>
      //           <TipTd className="tipTd">無天氣資料</TipTd>
      //         </tr>
      //       </tbody>
      //     </TipTable>
      //   </TipDiv>
      // );
      // rtnVal.tipItem.styles.width = '100px';
      // rtnVal.tipItem.styles.top = '-100px';
    }

    return rtnVal;
  }, [wxinfo, solaTheme.componentColors]);

  return <IconTooltip iconItem={wItem.iconItem} tipItem={wItem.tipItem} />;
};

const TipDiv = styled.div`
  width: 100%;
`;

const TipTable = styled.table`
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;

  .tipTh {
    color: ${(p) => p.styles.thTxColor};
  }
  .tipTd {
    color: ${(p) => p.styles.tdTxColor};
  }
`;
const TipTh = styled.th`
  text-align: left;
`;
const TipTd = styled.td`
  text-align: right;
`;

export default Weather;
