import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import _ from 'lodash';

import { ReactComponent as BarChartIcon } from '../../images/svg/bar_chart.svg';
import { ReactComponent as DashboardIcon } from '../../images/svg/dashboard.svg';
// import { ReactComponent as DocumentDoubleIcon } from '../../images/svg/document_with_double.svg';
// import { ReactComponent as DocumentTailIcon } from '../../images/svg/document_with_tail.svg';
// import { ReactComponent as MoneyIcon } from '../../images/svg/money.svg';
import { ReactComponent as SolaSignIcon } from '../../images/svg/sola_sign.svg';
// import { ReactComponent as PowerIcon } from '../../images/svg/power.svg';
import { ReactComponent as WeathreIcon } from '../../images/svg/weather.svg';
import ExpandIcon from '../../images/svg/expand_more_double_arrow.svg';

import Button from '@material-ui/core/Button';
import AccumulatedProfit from '../../components/Dashboard/AccumulatedProfit';
import AchieveRateList from '../../components/Dashboard/AchieveRateList';
// import AssetInfo from '../../components/Dashboard/AssetInfo';
// import BulletinList from '../../components/Dashboard/BulletinList';
import Loading from '../../components/Loading';
import ProfitCard from '../../components/ProfitCard';
// import DropDownList from '../../components/Selector/dropdownlist';
import HintWithIcon from '../../components/Title/HintWithIcon';
import TitleContainer from '../../components/Title/TitleContainer';
import TitleWithIcon from '../../components/Title/TitleWithIcon';
import EmptyMsg from '../../components/EmptyMsg';

import { useProvided } from '../../stores/index';
import useAuth from '../../stores/useAuth';
//import useAssetTheme from '../../stores/useAssetTheme';

/**
 * 共用變數
 */
// const dashboard_tab = [
//   {
//     id: 'myPvs',
//     title: '我的電廠',
//     icon: PowerIcon,
//   },
// ];
// const dashboard_sort = [
//   {
//     key: 'name',
//     name: '電廠名稱',
//   },
//   {
//     key: 'roi',
//     name: '本日投資報酬率',
//   },
// ];
// const asset_info_tab = [
//   {
//     id: 'assetInfo',
//     title: '資產總覽',
//     icon: DocumentDoubleIcon,
//   },
// ];
const CancelFnList = [];

/**
 * 自訂樣式元件
 */
const PanelMyPvs = styled.div`
  min-height: 290px;
  padding: 0px 10px;
  display: flex;
  flex-wrap: ${(props) => (props.expanded ? 'wrap' : 'nowrap')};
  overflow-x: auto;
`;
const PanelComingPvs = styled.div`
  width: 100%;
  min-height: 30px;
  display: inline-flex;
  padding-left: 30px;
`;
// const PanelPvsInfo = styled.div`
//   width: 100%;
//   min-height: 380px;
//   display: flex;
//   justify-content: space-around;
// `;
const PanelTwoColumnInfo = styled.div`
  width: 100%;
  min-height: 360px;
  padding-top: 20px;
  display: inline-flex;
`;
const PanelTwoColumnInfoInner = styled.div`
  width: ${(props) => props.width || '50%'};
  min-height: 360px;
`;

const reviewTab = 'myPvs';

/**
 * 主要元件
 */
const Dashboard = (props) => {
  //頁面狀態控制與函式
  // const [reviewTab, setReviewTab] = useState('myPvs');
  //const [reviewSort, setReviewSort] = useState('name');
  const [reviewExpanded, setReviewExpanded] = useState(false);
  // const [infoTab, setInfoTab] = useState('assetInfo');
  // const [bulletinPageNumber, setBulletinPageNumber] = useState(1);
  const [loadingProfit, setLoadingProfit] = useState(true);

  const { currentSPV } = useProvided(useAuth);
  //const { solaTheme } = useProvided(useAssetTheme);
  const [dataDashboardPvs, setDataDashboardPvs] = useState([]);
  const [dataDashboardPvsSummary, setDataDashboardPvsSummary] = useState({});
  const [dataInfoAccumulateProfit, setDataInfoAccumulateProfit] = useState([]);
  // const [dataInfoAssetOverview, setDataInfoAssetOverview] = useState({});
  // const [dataBulletins, setDataBulletins] = useState({});
  const [dataPvAchievement, setDataPvAchievement] = useState([]);

  //View Control
  // const handleReivewTabChange = (tab) => () => {
  //   if (reviewTab !== tab) {
  //     setReviewTab(tab);
  //   }
  // };
  // const handleReviewSortChange = (e) => {
  //   if (reviewSort !== e.target.value) {
  //     let sortData = [...dataDashboardPvs];
  //     switch (e.target.value) {
  //       case 'name':
  //         sortData = sortData.sort((a, b) => {
  //           if (a.id < b.id) {
  //             return -1;
  //           }
  //           if (a.id > b.id) {
  //             return 1;
  //           }
  //           return 0;
  //         });
  //         break;
  //       case 'roi':
  //         sortData = sortData.sort((a, b) => {
  //           return b.roi - a.roi;
  //         });
  //         break;
  //       default:
  //         break;
  //     }

  //     setReviewSort(e.target.value);
  //     setDataDashboardPvs(sortData);
  //   }
  // };
  const handleReivewExpanded = () => {
    setReviewExpanded((r) => !r);
  };
  // const handleInfoTabChange = (tab) => () => {
  //   if (infoTab !== tab) {
  //     setInfoTab(tab);
  //   }
  // };

  // //Data Control
  // const handleBulletinPageChange = (pageIndex) => () => {
  //   setBulletinPageNumber(pageIndex);
  // };

  //View DOM Generate.
  const myPvsDom = useMemo(() => {
    if (loadingProfit) {
      return <Loading />;
    } else {
      if (dataDashboardPvs && dataDashboardPvs.length > 0) {
        const innerDom = [];
        //summary
        innerDom.push(<ProfitCard key="summary0" isSummary={true} data={dataDashboardPvsSummary} />);
        //detail
        dataDashboardPvs.forEach((pv, index) => {
          if (reviewExpanded) {
            innerDom.push(<ProfitCard key={index} isSummary={false} data={pv} />);
          } else {
            if (index <= 3) {
              innerDom.push(<ProfitCard key={index} isSummary={false} data={pv} />);
            }
          }
        });
        //
        return (
          <div>
            <PanelMyPvs expanded={reviewExpanded}>{innerDom}</PanelMyPvs>
            <TitleContainer transparent>
              <HintWithIcon icon={WeathreIcon} text="當地天氣以中央氣象局為準" />
              <Button onClick={handleReivewExpanded}>
                <img
                  alt="expanded block"
                  src={ExpandIcon}
                  style={{ transform: reviewExpanded ? 'rotate(180deg)' : '' }}
                />
              </Button>
              <HintWithIcon
                icon={SolaSignIcon}
                text="關於 Sola Hint"
                styles={{ width: '240px', align: 'flex-end' }}
              />
            </TitleContainer>
          </div>
        );
      } else {
        return <EmptyMsg msg="尚未建立您所持有的電廠資料。" />;
      }
    }
  }, [loadingProfit, dataDashboardPvs, dataDashboardPvsSummary, reviewExpanded]);
  const comingPvsDom = useMemo(() => {
    return (
      <PanelComingPvs>
        <EmptyMsg msg="即將上線電廠頁面，設計中。" />
      </PanelComingPvs>
    );
  }, []);

  //Data Loading.
  const loadDashboardPvs = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/dashboard/myPvs`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res && res.data) {
          const pvData = res.data.result.pvData;
          const singlePvCards = [];
          const summaryPvCard = {};

          pvData.forEach((pv, index) => {
            //single pv.
            singlePvCards.push({
              id: pv.pvId,
              title: pv.pvId === pv.pvName ? pv.pvId : `${pv.pvId} ${pv.pvName}`,
              profit: pv.todayProfit,
              lastSeven: pv.profitData,
              noteInfo: { owner: pv.ownedNotes, total: pv.totalNotes },
              wxinfo: pv.wxinfo,
              roi: pv.todayProfit / (pv.ownedNotes * 10),
            });

            //summary pv.
            if (index === 0) {
              summaryPvCard.title = '所有電廠';
              summaryPvCard.wxinfo = null;
              summaryPvCard.profit = 0;
              summaryPvCard.noteInfo = { owner: 0, total: 0 };
              summaryPvCard.lastSeven = null;
            }

            if (summaryPvCard.lastSeven) {
              if (pv.profitData) {
                pv.profitData.forEach((p, index) => {
                  if (summaryPvCard.lastSeven[index].date === p.date) {
                    if (p.value) {
                      summaryPvCard.lastSeven[index].value += p.value;
                    }
                  }
                });
              }
            } else {
              if (pv.profitData) {
                summaryPvCard.lastSeven = _.cloneDeep(pv.profitData);
              }
            }
            summaryPvCard.profit += pv.todayProfit || 0;
            summaryPvCard.noteInfo.owner += pv.ownedNotes;
            summaryPvCard.noteInfo.total += pv.totalNotes;
          });

          setDataDashboardPvs(singlePvCards);
          setDataDashboardPvsSummary(summaryPvCard);
        }
        setLoadingProfit(false);
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
        setLoadingProfit(false);
      });
  }, [currentSPV]);
  const loadAccumulateProfit = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/dashboard/info/accumulateProfit`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res && res.data) {
          const accumulateProfit = res.data.result.accumulateProfit;
          setDataInfoAccumulateProfit(accumulateProfit);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);
  // const loadAssetOverview = useCallback(() => {
  //   const source = axios.CancelToken.source();
  //   CancelFnList.push(source.cancel);
  //   axios
  //     .get(`/api/v1/dashboard/info/assetOverview`, {
  //       headers: { Authorization: currentSPV.token },
  //       cancelToken: source.token,
  //     })
  //     .then((res) => {
  //       if (res && res.data) {
  //         setDataInfoAssetOverview(res.data.result.assetOverview);
  //       }
  //     })
  //     .catch((err) => {
  //       if (!axios.isCancel(err)) {
  //         console.error(err);
  //       }
  //     });
  // }, [currentSPV]);
  const loadPvAchievementRate = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/pv/achievementRate`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res && res.data) {
          const rateData = res.data.result.data;

          const achievementData = [];
          rateData.forEach((r) => {
            achievementData.push({
              pvId: r.id,
              pvName: r.pvName,
              rate: r.achievementRate,
            });
          });

          setDataPvAchievement(achievementData);
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV]);
  // const loadBulletins = useCallback(() => {
  //   const source = axios.CancelToken.source();
  //   CancelFnList.push(source.cancel);
  //   axios
  //     .get(`/api/v1/dashboard/bulletins?pageNumber=${bulletinPageNumber}&pageSize=6`, {
  //       headers: { Authorization: currentSPV.token },
  //       cancelToken: source.token,
  //     })
  //     .then((res) => {
  //       if (res && res.data) {
  //         setDataBulletins(res.data.result.bulletins);
  //       }
  //     })
  //     .catch((err) => {
  //       if (!axios.isCancel(err)) {
  //         console.error(err);
  //       }
  //     });
  // }, [bulletinPageNumber, currentSPV]);

  useEffect(() => {
    loadDashboardPvs();
    loadAccumulateProfit();
    // loadAssetOverview();
    loadPvAchievementRate();
    // loadBulletins();

    const refresh = setInterval(() => {
      //setReviewSort('name');
      setLoadingProfit(true);
      loadDashboardPvs();
    }, 900000); //every 15 minutes.

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach((fn) => {
          fn();
        });
      }

      clearInterval(refresh);
    };
  }, [loadDashboardPvs, loadAccumulateProfit, loadPvAchievementRate]);

  return (
    <div>
      <TitleContainer>
        <TitleWithIcon title="收益儀表板" prefixIcon={DashboardIcon} />
        {/*
        <TabPages
          current={reviewTab}
          pages={dashboard_tab}
          secondSelectColor={false}
          switchTabFn={handleReivewTabChange}
        />
       <DropDownList
          label="排序"
          value={reviewSort}
          options={dashboard_sort}
          width="150px"
          changeFn={handleReviewSortChange}
        /> 
        */}
      </TitleContainer>
      {reviewTab === 'myPvs' ? myPvsDom : null}
      {reviewTab === 'comingPvs' ? comingPvsDom : null}

      <PanelTwoColumnInfo>
        <PanelTwoColumnInfoInner width="52%">
          <TitleContainer>
            <TitleWithIcon title="累積收益暫結預估" prefixIcon={BarChartIcon} />
          </TitleContainer>
          <br />
          <br />
          <br />
          <AccumulatedProfit data={dataInfoAccumulateProfit} />
        </PanelTwoColumnInfoInner>
        <PanelTwoColumnInfoInner width="48%">
          <TitleContainer>
            <TitleWithIcon title="年度發電達成率累積估計" prefixIcon={BarChartIcon} />
          </TitleContainer>
          <AchieveRateList data={dataPvAchievement} />
        </PanelTwoColumnInfoInner>
      </PanelTwoColumnInfo>
    </div>
  );
};

export default Dashboard;
