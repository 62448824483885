import React from 'react';
import { ReactComponent as DashboardIcon } from '../../images/svg/dashboard.svg';
// import { ReactComponent as LayersIcon } from '../../images/svg/layers.svg';
import { ReactComponent as PowerIcon } from '../../images/svg/power.svg';
import { ReactComponent as CalendarIcon } from '../../images/svg/calendar.svg';
// import { ReactComponent as DocumentIcon } from '../../images/svg/document.svg';
// import { ReactComponent as WalletWithDotIcon } from '../../images/svg/wallet_with_dot.svg';

// import { ReactComponent as BookmarkIcon } from '../../images/svg/bookmark.svg';
// import { ReactComponent as PieChartIcon } from '../../images/svg/pie_chart.svg';
// import { ReactComponent as WalletIcon } from '../../images/svg/wallet.svg';
// import { ReactComponent as BarChartIcon } from '../../images/svg/bar_chart.svg';

import { ReactComponent as UserIcon } from '../../images/svg/user.svg';
// import { ReactComponent as ListWithDotIcon } from '../../images/svg/list_with_dot.svg';

const menuData = [
  {
    name: '收益儀表板',
    icon: <DashboardIcon />,
    path: '/app/dashboard',
    menu: '',
    page: 'dashboard',
    layer: 1,
    submenu: [],
  },
  {
    name: '電廠資料',
    icon: <PowerIcon />,
    path: '',
    menu: 'pvdata',
    page: '',
    layer: 3,
    submenu: [
      {
        name: '我的電廠',
        icon: <UserIcon />,
        path: '',
        menu: 'myPvs',
        page: '',
        submenu: [],
      },
      // {
      //   name: '即將上線電廠',
      //   icon: <AppsIcon />,
      //   path: '',
      //   menu: 'comingPvs',
      //   page: '',
      //   submenu: [
      //     {
      //       name: 'SNTW7 竹山餐廳',
      //       icon: NewIcon,
      //       path: '/app/pvdata/comingPvs/SNTW7',
      //       menu: '',
      //       page: 'SNTW7',
      //       submeu: []
      //     },
      //     {
      //       name: 'SNTW8 官田華宏',
      //       icon: NewIcon,
      //       path: '/app/pvdata/comingPvs/SNTW8',
      //       menu: '',
      //       page: 'SNTW8',
      //       submeu: []
      //     }
      //   ]
      // },
    ],
  },
  {
    name: '事件日曆',
    icon: <CalendarIcon />,
    path: '/app/calendar',
    menu: '',
    page: 'calendar',
    layer: 1,
    submenu: [],
  },
  // {
  //   name: '交易明細',
  //   icon: <DocumentIcon />,
  //   path: '',
  //   menu: 'transaction',
  //   page: '',
  //   layer: 2,
  //   submenu: [
  //     {
  //       name: '收益紀錄',
  //       icon: <DocumentIcon />,
  //       path: '/app/transaction/incomeRecord',
  //       menu: '',
  //       page: 'incomeRecord',
  //       submeu: []
  //     },
  //     {
  //       name: '提款紀錄',
  //       icon: <DocumentIcon />,
  //       path: '/app/transaction/withdrawRecord',
  //       menu: '',
  //       page: 'withdrawRecord',
  //       submeu: []
  //     }
  //   ]
  // },
  // {
  //   name: '資產總覽',
  //   icon: <WalletWithDotIcon />,
  //   path: '/app/assets',
  //   menu: '',
  //   page: 'assets',
  //   layer: 1,
  //   submenu: []
  // },
  // {
  //   name: '投資組合',
  //   icon: <LayersIcon />,
  //   path: '',
  //   menu: 'portfolio',
  //   page: '',
  //   layer: 2,
  //   submenu: [
  //     {
  //       name: '摘要',
  //       icon: <BookmarkIcon />,
  //       path: '/app/portfolio/abstract',
  //       menu: '',
  //       page: 'abstract',
  //       submenu: []
  //     },
  //     {
  //       name: '今日分析',
  //       icon: <ListWithDotIcon />,
  //       path: '/app/portfolio/todayAnalysis',
  //       menu: '',
  //       page: 'todayAnalysis',
  //       submenu: []
  //     },
  //     {
  //       name: '累積報酬',
  //       icon: <WalletIcon />,
  //       path: '/app/portfolio/cumulativeIncome',
  //       menu: '',
  //       page: 'cumulativeIncome',
  //       submenu: []
  //     },
  //     {
  //       name: '收益分析',
  //       icon: <PieChartIcon />,
  //       path: '/app/portfolio/incomeAnalysis',
  //       menu: '',
  //       page: 'incomeAnalysis',
  //       submenu: []
  //     },

  //     {
  //       name: '績效分析(近期)',
  //       icon: <BarChartIcon />,
  //       path: '/app/portfolio/performanceAnalysisRecent',
  //       menu: '',
  //       page: 'performanceAnalysisRecent',
  //       submenu: []
  //     },
  //     {
  //       name: '績效分析(週期)',
  //       icon: <BarChartIcon />,
  //       path: '/app/portfolio/performanceAnalysisCycle',
  //       menu: '',
  //       page: 'performanceAnalysisCycle',
  //       submenu: []
  //     },

  //     {
  //       name: '達成率分析(近期)',
  //       icon: <ListWithDotIcon />,
  //       path: '/app/portfolio/achieveRateAnalysisRecent',
  //       menu: '',
  //       page: 'achieveRateAnalysisRecent',
  //       submenu: []
  //     },
  //     {
  //       name: '達成率分析(週期)',
  //       icon: <ListWithDotIcon />,
  //       path: '/app/portfolio/achieveRateAnalysisCycle',
  //       menu: '',
  //       page: 'achieveRateAnalysisCycle',
  //       submenu: []
  //     }
  //   ]
  // }
];

export default menuData;
