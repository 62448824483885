import React from 'react';
import styled from 'styled-components';
import { IntegerFormat } from '../../commons/utils';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const Day = ({ date, isSelected, isSameMonth, dataPv, dataSimulate, dataBill, dateChangeFn }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const colors = {
    sameMonthBg: solaTheme.componentColors.CalendarDaySameMonthBgColor,
    otherMonthBg: solaTheme.componentColors.CalendarDayOtherMonthBgColor,
    date: solaTheme.componentColors.CalendarDayTxColor,
    pv: solaTheme.componentColors.CalendarPvEventColor,
    simulate: solaTheme.componentColors.CalendarSimulateEventColor,
    bill: solaTheme.componentColors.CalendarBillEventColor,
    selectColor: solaTheme.componentColors.CalendarDaySelectColor,
  };

  return (
    <DayCotainer
      selected={isSelected}
      isSameMonth={isSameMonth}
      onClick={dateChangeFn(date)}
      bgColor={isSameMonth ? colors.sameMonthBg : colors.otherMonthBg}
      selectColor={colors.selectColor}
    >
      <DateItem color={colors.date}>{date}</DateItem>
      {dataPv && dataPv.length > 0
        ? dataPv.map((d, index) => {
            return (
              <InfoItem key={index} color={colors.pv}>
                {d.pvId}：{d.name}
              </InfoItem>
            );
          })
        : null}
      {dataSimulate && dataSimulate.length > 0
        ? dataSimulate.map((d, index) => {
            return (
              <InfoItem key={index} color={colors.simulate}>
                推估收益：{IntegerFormat(d.profit, '', '元')}
              </InfoItem>
            );
          })
        : null}
      {dataBill && dataBill.length > 0
        ? dataBill.map((d, index) => {
            return (
              <InfoItem key={index} color={colors.bill}>
                暫結收益：{IntegerFormat(d.profit, '', '元')}
              </InfoItem>
            );
          })
        : null}
    </DayCotainer>
  );
};

const DayCotainer = styled.div`
  width: 140px;
  height: 140px;
  overflow-y: auto;

  background-color: ${(p) => p.bgColor};
  border: ${(p) => (p.selected ? '1px solid ' + p.selectColor : '')};
  cursor: pointer;
`;
const DateItem = styled.div`
  text-align: right;
  color: ${(p) => p.color};
  font-size: 12px;
  padding: 5px;
`;
const InfoItem = styled.div`
  font-size: 12px;
  color: ${(p) => p.color};
  padding-left: 6px;
`;

export default Day;
