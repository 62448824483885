import React from 'react';
import styled from 'styled-components';
import TitleContainer from '../Title/TitleContainer';
import TitleWithIcon from '../Title/TitleWithIcon';
import LineStepPng from '../../images/line_step.png';
import PointYellowIcon from '../../images/svg/point_yellow.svg';
import PointYellowSelectIcon from '../../images/svg/point_yellow_select.svg';
import { ReactComponent as DefaultIcon } from '../../images/svg/point_default.svg';
import { ReactComponent as FileIcon } from '../../images/svg/picture.svg';
import { ReactComponent as ClockIcon } from '../../images/svg/time.svg';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

//PvStage Settings.
const Width = 900;
const MarginLeft = 72;
const MarginTop = 78;
const StepWidth = 147;
const stages = [
  '公證租約',
  '併聯審查通過',
  '電業籌設核准',
  '同意備案',
  '施工許可',
  '併聯試運轉',
  '核發電業執照',
  '購售電契約執行',
];

const PvStage = ({ stage, stageDetail, onExpand }) => {
  const { solaTheme } = useProvided(useAssetTheme);
  const currentStep = stage && stage - 1;
  const stageItemDom = [];

  const baseLineWidth = (stages.length - (stage || 1)) * StepWidth;
  const baseLineProps = {
    width: baseLineWidth,
    top: MarginTop,
    left: Width - baseLineWidth + MarginLeft,
  };

  for (let i = 0; i < stages.length; i++) {
    const stageName = stages[i];
    const detail = stageDetail && stageDetail[stageName];

    const itemProps = {
      date: '',
      stepNum: i + 1,
      stepName: stageName,
      showFileIcon: false,
      pointType: 'current',
      clickFn: null,
    };
    //
    if (detail && detail.date) {
      itemProps.date = detail.date;
      itemProps.showFileIcon = detail.files.length > 0;
    }
    //
    if (currentStep < i) {
      itemProps.pointType = 'future';
    } else if (currentStep === i) {
      itemProps.pointType = 'current';
    } else {
      itemProps.pointType = 'pass';
    }
    //
    if (itemProps.showFileIcon) {
      itemProps.clickFn = onExpand(stageName);
    }

    stageItemDom.push(<StageItem key={i} {...itemProps} />);
  }

  const stageStyle = {
    baseLineColor: solaTheme.componentColors.PvStageBaseLineColor,
    basePointColor: solaTheme.componentColors.PvStageBaseLineColor,
    passTxColor: solaTheme.componentColors.PvStagePassStageTxColor,
    currentTxColor: solaTheme.componentColors.PvStageCurrentStageTxColor,
    futureTxColor: solaTheme.componentColors.PvStageFutureStageTxColor,
  };

  return (
    <div>
      <TitleContainer hideTop>
        <TitleWithIcon title="建置時程" prefixIcon={ClockIcon} />
      </TitleContainer>
      <StageContainer {...stageStyle}>
        <ProcessLine alt="" src={LineStepPng} left={MarginLeft} top={MarginTop} width={Width} />
        <BaseLine className="baseline" {...baseLineProps} />
        {stageItemDom}
      </StageContainer>
    </div>
  );
};

const StageContainer = styled.div`
  width: 96%;
  height: 200px;
  margin: 20px 2% 30px 2%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .passItem {
    color: ${(p) => p.passTxColor};
  }
  .passItem .svgBase {
    fill: ${(p) => p.passTxColor};
  }
  .currentItem {
    color: ${(p) => p.currentTxColor};
  }
  .currentItem .svgBase {
    fill: ${(p) => p.currentTxColor};
  }
  .futureItem {
    color: ${(p) => p.futureTxColor};
  }
  .futureItem .svgBase {
    fill: ${(p) => p.futureTxColor};
  }
  .baseline {
    background-color: ${(p) => p.baseLineColor};
  }
  .defaultIcon .svgBase {
    fill: ${(p) => p.baseLineColor};
  }
`;
const BaseLine = styled.div`
  height: 5px;
  position: absolute;
  z-index: 10;

  width: ${(p) => p.width}px;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
`;
const ProcessLine = styled.img`
  height: 5px;
  position: absolute;
  z-index: 10;

  width: ${(p) => p.width}px;
  left: ${(p) => p.left}px;
  top: ${(p) => p.top}px;
`;

const StageItem = ({ date, stepNum, stepName, showFileIcon, pointType, clickFn }) => {
  let iconDom = null;
  let className = '';
  switch (pointType) {
    case 'pass':
      iconDom = <img alt={pointType} src={PointYellowIcon} width="20px" height="20px" />;
      className = 'passItem';
      break;
    case 'current':
      iconDom = <img alt={pointType} src={PointYellowSelectIcon} width="60px" height="60px" />;
      className = 'currentItem';
      break;
    case 'future':
    default:
      iconDom = (
        <ItemDivIcon>
          <DefaultIcon className="defaultIcon" />
        </ItemDivIcon>
      );
      className = 'futureItem';
      break;
  }

  return (
    <ItemContainer className={className}>
      <ItemDivDate>{date}</ItemDivDate>
      <ItemDivPoint>{iconDom}</ItemDivPoint>
      <ItemDivStepInfo disable={clickFn === null} onClick={clickFn}>
        <ItemDivIcon>{showFileIcon ? <FileIcon /> : null}</ItemDivIcon>
        <div>Step {stepNum}</div>
        <div>{stepName}</div>
      </ItemDivStepInfo>
    </ItemContainer>
  );
};

const ItemContainer = styled.div`
  width: 14%;
  height: 200px;
  font-size: 15px;
  z-index: 99;
  padding-top: 20px;
`;
const ItemDivDate = styled.div`
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ItemDivPoint = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ItemDivStepInfo = styled.div`
  width: 100%;
  height: 80px;
  cursor: ${(p) => (p.disable === true ? 'default' : 'pointer')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;
const ItemDivIcon = styled.div`
  width: 20px;
  height: 20px;
`;

export default PvStage;
