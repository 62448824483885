import React, { useMemo } from 'react';
import styled from 'styled-components';
import { PercentFormat, NumberFormat, DisplayFormat } from '../../commons/utils';
import TitleContainer from '../Title/TitleContainer';
import TitleWithIcon from '../Title/TitleWithIcon';
import { ReactComponent as PowerIcon } from '../../images/svg/power.svg';
import { ReactComponent as PowerDetectIcon } from '../../images/svg/power-detect.svg';
//
import { useProvided } from '../../stores/index';
import useAssetTheme from '../../stores/useAssetTheme';

const PvData = ({ data }) => {
  const { solaTheme } = useProvided(useAssetTheme);

  const pvStatusItem = useMemo(() => {
    const rtnVal = {};

    switch (data.status) {
      case 0:
        rtnVal.color = solaTheme.componentColors.PvStatusPowerOnColor;
        rtnVal.code = '發電中';
        break;
      case 1:
        rtnVal.color = solaTheme.componentColors.PvStatusPowerBuildColor;
        rtnVal.code = '建置中';
        break;
      case 2:
        rtnVal.color = solaTheme.componentColors.PvStatusPowerMaintainColor;
        rtnVal.code = '維修中';
        break;
      default:
        // 灰色的維修中 => 其實是未知
        rtnVal.color = solaTheme.componentColors.PvStatusNoneColor;
        rtnVal.code = '維修中';
        break;
    }

    return rtnVal;
  }, [solaTheme.componentColors, data.status]);

  const infoStyle = useMemo(() => {
    return {
      titleTxColor: solaTheme.componentColors.PvDataTitleTxColor,
      valueTxColor: solaTheme.componentColors.PvDataValueTxColor,
      borderColor: solaTheme.componentColors.PvDataColumnBorderColor,
    };
  }, [solaTheme.componentColors]);

  return (
    <div>
      <TitleContainer>
        <TitleWithIcon
          title={data.pvId === data.pvName ? data.pvId : `${data.pvId || ''} ${data.pvName || ''}`}
          prefixIcon={PowerIcon}
        />
        <PvStatusContainer txColor={pvStatusItem.color}>
          <PvStatusIcon>
            <PowerDetectIcon />
          </PvStatusIcon>
          <PvStatusName className="status">{pvStatusItem.code}</PvStatusName>
        </PvStatusContainer>
      </TitleContainer>
      <InfoContainer {...infoStyle}>
        <Row>
          <Column className="column">
            <div className="title">電廠代號</div>
            <div className="value">{data.projectCode}</div>
          </Column>
          <Column className="column" col={2}>
            <div className="title">電廠名稱</div>
            <div className="value">{data.pvName}</div>
          </Column>
        </Row>
        <Row>
          <Column className="column">
            <div className="title">專案代號</div>
            <div className="value">T7422</div>
          </Column>
          <Column className="column" col={2}>
            <div className="title">專案名稱</div>
            <div className="value">天璣</div>
          </Column>
        </Row>
        <Row>
          <Column className="column">
            <div className="title">發電年衰減率</div>
            <div className="value">{PercentFormat(data.decay)}</div>
          </Column>
          <Column className="column" col={2}>
            <div className="title">地號</div>
            <div className="value">{data.location}</div>
          </Column>
        </Row>
        <Row>
          <Column className="column">
            <div className="title">裝置容量</div>
            <div className="value">{DisplayFormat(data.capacity, '', '瓩')}</div>
          </Column>
          <Column className="column">
            <div className="title">單位成本</div>
            <div className="value">{DisplayFormat(data.constructionCost, '', '元/瓩')}</div>
          </Column>
          <Column className="column">
            <div className="title">總系統成本</div>
            <div className="value">{DisplayFormat(data.totalCapital, '', '元')}</div>
          </Column>
        </Row>
        <Row>
          <Column className="column">
            <div className="title">今年保證發電量</div>
            <div className="value">{NumberFormat(data.thisYearAnnualAmount, '', '度/瓩')}</div>
          </Column>
          <Column className="column">
            <div className="title">躉售電價</div>
            <div className="value">{DisplayFormat(data.sellPrice, '', '元/度')}</div>
          </Column>
          <Column className="column">
            <div className="title">掛錶日</div>
            <div className="value">{data.validityDateBegin || '尚未發電'}</div>
          </Column>
        </Row>
        {/* <Row>
          <Column className="column">
            <div className="title">發行量</div>
            <div className="value">{DisplayFormat(data.ownedCapital / 10, '', '個')}</div>
          </Column>
          <Column className="column">
            <div className="title">發行價</div>
            <div className="value">{DisplayFormat(data.initialPrice, '', '元/個')}</div>
          </Column>
          <Column className="column">
            <div className="title">發行者</div>
            <div className="value">{data.owner}</div>
          </Column>
        </Row> */}
      </InfoContainer>
    </div>
  );
};

const InfoContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;

  .column {
    border-bottom: 1px solid ${(p) => p.borderColor};
  }
  .title {
    color: ${(p) => p.titleTxColor};
  }
  .value {
    color: ${(p) => p.valueTxColor};
  }
`;
const Row = styled.div`
  width: 98%;
  height: 50px;
  margin: 0% 1%;
  display: flex;
  justify-content: space-between;
`;
const Column = styled.div`
  width: ${(p) => (!p.col || p.col === 1 ? '28%' : p.col === 2 ? '62%' : '100%')};
  padding: 0% 2%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const PvStatusContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 27px;

  .svgBase {
    fill: ${(p) => p.txColor};
  }
  .status {
    color: ${(p) => p.txColor};
  }
`;
const PvStatusIcon = styled.div`
  margin-right: 10px;
`;
const PvStatusName = styled.div`
  font-size: 18px;
`;

export default PvData;
