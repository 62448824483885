import React from 'react';
import styled from 'styled-components';

/**
 * iconItem: {
 *  icon: <SVG>,
 *  alt: "description_text",
 *  width: <WIDTH>,
 *  height: <HEIGHT>
 * }
 */
const IconTooltip = ({ iconItem, tipItem }) => {
  let tipDom = null;
  if (tipItem && (!!tipItem.dom || !!tipItem.text)) {
    tipDom = (
      <Tip className="tooltiptext" styles={tipItem.styles}>
        {tipItem.dom || tipItem.text}
      </Tip>
    );
  }

  return (
    <IconContainer
      width={iconItem.width}
      height={iconItem.height}
      custom={iconItem.useCustomColor}
      baseColor={iconItem.baseColor}
      hrColor={iconItem.hoverColor}
    >
      <Icon className="tooltipIcon">
        {iconItem.icon && React.createElement(iconItem.icon)}
        {!iconItem.icon && iconItem.iconImg && (
          <IconImg className="tooltipIconImg" alt={iconItem.alt} src={iconItem.iconImg} />
        )}
      </Icon>
      {tipDom}
    </IconContainer>
  );
};

const IconContainer = styled.div`
  display: inline-block;
  width: ${(p) => p.width || '20px'};
  height: ${(p) => p.height || '20px'};

  .tooltipIconImg {
    width: ${(p) => p.width || '20px'};
    height: ${(p) => p.height || '20px'};
  }
  .svgBase {
    fill: ${(p) => (p.custom ? p.baseColor : '')};
  }

  &:hover .svgHover {
    fill: ${(p) => (p.custom ? p.hrColor : '')};
  }

  &:hover .tooltiptext {
    visibility: visible;
  }
`;
const Tip = styled.div`
  width: ${(p) => p.styles.width || '100px'};
  top: ${(p) => p.styles.top || '0px'};
  left: ${(p) => p.styles.left || '0px'};
  background-color: ${(p) => p.styles.bgColor || '#000'};

  visibility: hidden;
  padding: 5px;
  border-radius: 6px;

  position: relative;
  z-index: 100;
`;
const Icon = styled.div`
  margin-bottom: 5px;
`;
const IconImg = styled.img`
  width: 48px;
  height: 48px;
`;

export default IconTooltip;
