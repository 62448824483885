import React, { useState, useCallback, useEffect, useMemo } from 'react';
import axios from 'axios';
import menuData from './menuData';
import { ReactComponent as PointRedIcon } from '../../images/svg/point_red.svg';
import { ReactComponent as PointGreenIcon } from '../../images/svg/point_green.svg';
import { ReactComponent as NgoIcon } from '../../images/svg/ngo.svg';
import OneLayerMenuItem from './oneLayerMenuItem';
import TwoLayerMenuItem from './twoLayerMenuItem';
import ThreeLayerMenuItem from './threeLayerMenuItem';
import { useProvided } from '../../stores/index';
import useAuth from '../../stores/useAuth';

const CancelFnList = [];
const Menu = (props) => {
  const { currentSPV } = useProvided(useAuth);

  const currentMenuData = useMemo(() => {
    if (currentSPV.userName === '索拉清華') {
      const tmp = [...menuData];
      tmp.push({
        name: '太陽能捐贈',
        icon: <NgoIcon />,
        path: '/app/donation',
        menu: '',
        page: 'donation',
        layer: 1,
        submenu: [],
      });
      return tmp;
    } else {
      return menuData;
    }
  }, [currentSPV]);

  const handleMyPvList = useCallback(() => {
    const source = axios.CancelToken.source();
    CancelFnList.push(source.cancel);
    axios
      .get(`/api/v1/dashboard/userPv`, {
        headers: { Authorization: currentSPV.token },
        cancelToken: source.token,
      })
      .then((res) => {
        if (res && res.data) {
          const pvData = res.data.result.userPv;
          const myPvList = [];
          pvData.forEach((pv, index) => {
            myPvList.push({
              name: pv.pvId === pv.pvName ? pv.pvId : `${pv.pvId} ${pv.pvName}`,
              icon: pv.status !== 0 ? <PointRedIcon /> : <PointGreenIcon />,
              icon_select: pv.status !== 0 ? <PointRedIcon /> : <PointGreenIcon />,
              path: `/app/pvdata/myPvs/${pv.pvId}`,
              menu: '',
              page: pv.pvId,
              submenu: [],
            });
          });
          currentMenuData
            .find((item) => {
              return item.name === '電廠資料';
            })
            .submenu.find((item) => {
              return item.name === '我的電廠';
            }).submenu = myPvList;
        }
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          console.error(err);
        }
      });
  }, [currentSPV, currentMenuData]);

  const [expandedPanel, setExpandedPanel] = useState(false);
  const handlePanelExpanded = (panelName) => (e, isExpanded) => {
    setExpandedPanel(isExpanded ? panelName : false);
  };

  const [expandedSecondPanel, setExpandedSecondPanel] = useState(false);
  const handleSecondPanelExpanded = (panelName) => (e, isExpanded) => {
    setExpandedSecondPanel(isExpanded ? panelName : false);
  };

  useEffect(() => {
    handleMyPvList();

    return () => {
      if (CancelFnList && CancelFnList.length > 0) {
        CancelFnList.forEach((fn) => {
          fn();
        });
      }
    };
  }, [handleMyPvList]);

  return (
    <div>
      {currentMenuData.map((data, index) => {
        let view = null;
        switch (data.layer) {
          case 1:
            view = (
              <OneLayerMenuItem
                key={index}
                menu={data}
                routePage={props.page}
                onHandlePanel={handlePanelExpanded}
              />
            );
            break;
          case 2:
            view = (
              <TwoLayerMenuItem
                key={index}
                menu={data}
                routeMenu={props.menu}
                routePage={props.page}
                panel={expandedPanel}
                onHandlePanel={handlePanelExpanded}
              />
            );
            break;
          case 3:
            view = (
              <ThreeLayerMenuItem
                key={index}
                menu={data}
                routeMenu={props.menu}
                routePage={props.page}
                routePvId={props.pvId}
                panel={expandedPanel}
                onHandlePanel={handlePanelExpanded}
                secondPanel={expandedSecondPanel}
                onHandleSecondPanel={handleSecondPanelExpanded}
              />
            );
            break;
          default:
            break;
        }
        return view;
      })}
    </div>
  );
};

export default Menu;
