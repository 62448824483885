import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import Analytics from 'react-router-ga';
//components
import Avatar from '../components/Avatar';
import Menu from '../components/Menu';
import LogoDarkSvg from '../images/svg/logo_dark.svg';
//pages
// import Dev from './Dev';
import InnerError from './Error/innerError';
import Dashboard from './Dashboard';
import PvData from './PvData';
// import Abstract from './Portfolio/Abstract';
// import IncomeAnalysis from './Portfolio/IncomeAnalysis';
// import CumulativeIncome from './Portfolio/CumulativeIncome';
// import AchieveRateAnalysisRecent from './Portfolio/AchieveRateAnalysis/recent';
// import AchieveRateAnalysisCycle from './Portfolio/AchieveRateAnalysis/cycle';
// import performanceAnalysisRecent from './Portfolio/PerformanceAnalysis/recent';
// import performanceAnalysisCycle from './Portfolio/PerformanceAnalysis/cycle';
// import TodayAnalysis from './Portfolio/TodayAnalysis';
// import IncomeRecord from './Transaction/IncomeRecord';
// import WithdrawRecord from './Transaction/WithdrawRecord';
import UserAssets from './UserAssets';
import EventCalendar from './EventCalendar';
import ChangeSpv from './ChangeSpv';
import Account from './Account';
// import Donation from './Donation';
//config
import keys from '../config/keys';
//
import { useProvided } from '../stores/index';
import useAssetTheme from '../stores/useAssetTheme';

function AppLayout() {
  const { solaTheme } = useProvided(useAssetTheme);

  return (
    <BrowserRouter>
      <Analytics {...keys.gaProps}>
        <MainPanel>
          <LeftPanel bgColor={solaTheme.componentColors.SideBgColor}>
            <LeftTopPanel>
              <Avatar />
              <Switch>
                <Route
                  exact
                  path="/app/:menu/:page/:pvId"
                  render={({ match }) => (
                    <Menu menu={match.params.menu} page={match.params.page} pvId={match.params.pvId} />
                  )}
                />
                <Route
                  exact
                  path="/app/:menu/:page"
                  render={({ match }) => <Menu menu={match.params.menu} page={match.params.page} />}
                />
                <Route exact path="/app/:page" render={({ match }) => <Menu page={match.params.page} />} />
              </Switch>
            </LeftTopPanel>
            <LeftDownPanel>
              <img alt="dark-logo" src={LogoDarkSvg} width="88px" />
            </LeftDownPanel>
          </LeftPanel>
          <RightPanel bgColor={solaTheme.componentColors.ContentBgColor}>
            <Switch>
              <Route path="/app/changeSpv" component={ChangeSpv} />
              <Route path="/app/account" component={Account} />
              <Route path="/app/dashboard" component={Dashboard} />
              {/* <Route path="/app/portfolio/abstract" component={Abstract} />
              <Route path="/app/portfolio/incomeAnalysis" component={IncomeAnalysis} />
              <Route path="/app/portfolio/cumulativeIncome" component={CumulativeIncome} />
              <Route path="/app/portfolio/achieveRateAnalysisRecent" component={AchieveRateAnalysisRecent} />
              <Route path="/app/portfolio/achieveRateAnalysisCycle" component={AchieveRateAnalysisCycle} />
              <Route path="/app/portfolio/performanceAnalysisRecent" component={performanceAnalysisRecent} />
              <Route path="/app/portfolio/performanceAnalysisCycle" component={performanceAnalysisCycle} />
              <Route path="/app/portfolio/todayAnalysis" component={TodayAnalysis} /> */}
              <Route
                path="/app/pvdata/myPvs/:pvId"
                render={({ match }) => <PvData pvId={match.params.pvId} />}
              />
              {/*
              <Route
                path="/app/pvdata/comingPvs/:pvId"
                render={({ match }) => <Dev pvId={match.params.pvId} />}
              />
              */}
              <Route path="/app/calendar" component={EventCalendar} />
              {/* <Route path="/app/transaction/incomeRecord" component={IncomeRecord} />
              <Route path="/app/transaction/withdrawRecord" component={WithdrawRecord} /> */}
              <Route path="/app/assets" component={UserAssets} />
              {/* <Route path="/app/donation" component={Donation} /> */}
              {/* <Route path="/app/dev" component={Dev} /> */}
              {/* <Route path="/login" render={() => <div />} /> */}
              <Route component={InnerError} />
            </Switch>
          </RightPanel>
        </MainPanel>
      </Analytics>
    </BrowserRouter>
  );
}

const MainPanel = styled.div`
  width: 1330px;
  height: 85vh;
  display: inline-flex;
`;
const LeftPanel = styled.div`
  width: 260px;
  max-height: 85vh;
  background-color: ${(props) => props.bgColor};
`;
const LeftTopPanel = styled.div`
  width: 260px;
  height: 80vh;
  max-height: 80vh;

  overflow-y: auto;
  overflow-x: hidden;
`;
const LeftDownPanel = styled.div`
  width: 260px;
  height: 5vh;
  max-height: 5vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;
const RightPanel = styled.div`
  width: 1070px;
  max-width: 1070px;
  background-color: ${(props) => props.bgColor};

  overflow-y: auto;
  overflow-x: hidden;
`;

export default AppLayout;
